* {
  font-family: 'Lato', 'Kosugi', 'Balsamiq Sans', cursive, sans-serif;
}

body {
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  font-size: 1rem;
  background-color: #f5d44f;
}

#root {
  background-color: #f5d44f;
}

:root {
  background-color: #f5d44f;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

h2,
p,
div,
label,
img {
  font-family: 'Lato', 'Kosugi', sans-serif;
}

button {
  outline: none;
}

button:hover,
button:active {
  background-color: white;
  color: #2ecc71;
}

ion-icon {
  vertical-align: middle;
  font-size: 20px;
}

header {
  display: flex;
  justify-content: stretch;
  width: 100%;
  height: auto;
  background-color: #e74c3c;
  overflow-x: auto;
  overflow-y: hidden;
  z-index: 1;
  font-size: 1rem;
  line-height: .9rem;
  padding-bottom: 3px;
  box-shadow: 1px 1px 5px grey;
}

.sticky {
  position: fixed;
  top: 0;
  width: 100%;
}

nav {
  display: flex;
  align-items: center;
  margin: auto 10px;
}

