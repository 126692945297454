.successMessageBox {
    margin: auto;
    color:black;
    align-items: center;
}

.successMessage {
    font-size: 3vw;
    text-align: center;
}

.successMessage ion-icon {
    font-size: 8vw;
}