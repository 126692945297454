.create-game-box {
    background-color: rgb(231, 231, 231);
    display: flex;
    flex-direction: column;
    justify-content: center;
    padding: 25px 10px;
}

.create-game-box input {
    width: 75%;
    margin: 5px auto;
    padding: 5px;
}

.add-team-btn {
    width: 50%;
    margin: auto;
    padding: 5px;
}

.add-team-btn ion-icon {
    font-size: 30px;
}

.team-box {
    display: flex;
    align-items: center;
    justify-content: space-evenly;
    cursor: grab;
}

.team-box:nth-child(even) {
    background-color: #2ecc7067;
}

.hover-alert {
    background-color: yellow;
}

.drag-alert {
    background-color: red;
}