.fade-in-appear {
    opacity: 0.01;
}

.fade-in-appear.fade-in-appear-active {
    opacity: 1;
    transition: opacity .5s ease-in;
}

.fade-enter {
    opacity: 0;
}

.fade-enter-active {
    opacity: 1;
    transition: opacity 500ms;
}
.fade-exit {
    opacity: 1;
}
.fade-exit-active {
    opacity: 0;
    transition: opacity 500ms;
}

.CollectionItemBox {
    display: flex;
    position: relative;
    flex-direction: column;
    height: auto;
    border: 1px solid #cfd9db;
    flex-wrap: wrap;
    width: auto;
    background-color: #ecf0f1;
    box-shadow: 2px 5px 4px #1e874b;
    border-radius: 5px;
}

.CollectionItemTitle {
    margin: 30px auto 10px auto;
    padding: 0 5px;
    font-size: 20px;
    line-height: 1.2;
    width: 100%;
    word-wrap: break-word;
}

.CollectionItemTitle:after {
    display: block;
    text-align: center;
    width: 40%;
    margin-left: 30%;
    margin-top: 5px;
    height: 2px;
    background-color:#f5d44f;
    content:'';
}

.collection-preview-grid {
    display: grid;
    grid-template-columns: repeat(auto-fit, minmax(22%, 1fr));
    grid-template-rows: repeat(auto-fit, minmax(10%, 1fr));
    width: auto;
    height: auto;
    object-position: center;
    column-gap: 5px;
    row-gap: 5px;
    background-color: black;
}

.delete-cloud-save {
    width: 25px;
    height: 25px;
    border-radius: 1000px;
    padding: 0;
    margin: auto;
    position: absolute;
    top: 2%;
    left: 2%;
    background: none;
}

.delete-cloud-save:hover {
    padding: 0px;
    position: absolute;
    border: none;
    background-color: rgb(211, 211, 211);
}

.delete-cloud-save ion-icon {
    font-size: 20px;
    vertical-align: text-top;
    color: #2ecc71;
}

.collection-item-buttons-row {
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: space-evenly;
    margin: 10px auto;
}

.collection-item-buttons-row button {
    border-radius: 5px;
}

.collection-item-buttons-row ion-icon {
    font-size: 20px;
    vertical-align:text-top;
}

.show-items-section {
    background-color: rgb(219, 219, 219);
    border-radius: 5px;
    padding: 3px;
    justify-self: center;
    width: 100%;
}

.show-items-section ion-icon {
    font-size: 15px;
    color: grey;
}

.red {
    background-color: #e74c3c;
}