.board-topbar {
    background: none;
    text-align: center;
    width: 80%;
    overflow-x: auto;
    overflow-y: hidden;
    padding: 5px;
    flex-shrink: 0;
    margin-left: 10%;
}

.board-topbar-inner {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: stretch;
    height: 100%;
    width: auto;
}

.team-wrapper {
    flex-grow: 1;
    position: relative;
    background: white;
    z-index: 0;
    border-radius: 5px;
    border: 1px solid transparent;
    box-shadow: 2px 2px 2px #d2ab0c;
    margin: 2px 5px;
    width: auto;
    height: auto;
    max-height: 100%;
    display: flex;
    flex-direction: column;
}

.team-red-back {
    width: 100%;
    height: 80%;
    background-color: #e74c3c;
    position: absolute;
    top: 10%;
    left: 0;
    border-radius: 5px;
    animation: rise 1.3s infinite linear;
}

@keyframes rise {
    0% {box-shadow: 0px 2px 10px 4px grey;}
    50% {box-shadow: 0px 5px 10px 7px grey;}
    100% {box-shadow: 0px 2px 10px 4px grey;}
}

.team-info {
    position: relative;
    display: flex;
    flex-direction: row;
    justify-content: space-evenly;
    align-items: center;
    margin: auto;
    font-size: 20px;
    z-index: 3;
    overflow: hidden;
    width: 100%;
}

.team-info div {
    padding: 0 5px;
    margin: auto 5px;
    display: flex;
    align-items: center;
    flex: 1 1 auto;
}

.team-info p {
    white-space: nowrap;
    text-overflow: ellipsis;
    overflow: hidden;
    justify-content: flex-start;
    vertical-align: baseline;
}

.team-name {
    width: 150px;
    margin: auto 5px;
}

.team-points {
    width: auto;
    margin: auto 15px auto 5px;
}

.show-board-menu-section ion-icon {
    font-size: 18px;
    margin: auto;
    vertical-align: middle;
}

.card-icon ion-icon {
    font-size: .5vw;
}

.full-menu {
    padding: 10px;
}

.matched-cards-list {
    display: grid;
    grid-template-columns: repeat(auto-fill, minmax(21%, 1fr));
    grid-template-rows: repeat(auto-fit, minmax(20%, 1fr));
    margin: 1%;
    width: 100%;
    height: auto;
    max-height: 50vh;
    object-position: center;
    padding: 10px;
    column-gap: 5px;
    row-gap: 5px;
    background-color: black;
    color: white;
}

.matched-cards-list li{
    width: 100%;
    height: auto;
    margin: auto;
    padding: 0;
}

.matched-cards-list li img {
    margin: auto;
    height: auto;
    width: auto;
    max-width: 100%;
    object-fit: cover;
}

.matched-cards-list li p{
    font-size: 1rem;
}

.attack-button {
    padding: .5vw;
    font-size: 15px;
    margin: 1vh .5vw;
    display: inline-block;
    height: auto;
    width: auto;
    border-radius: 5px;
}

.attack-button-grey {
    background: rgba(128, 128, 128, 0.349);
    padding: .2vw;
    margin: .5vh .5vw;
    border: none;
    font-size: 15px;
    height: auto;
    width: auto;
    display: inline-block;
    border-radius: 5px;
}

.attack-button-grey:hover {
    background: rgba(128, 128, 128, 0.349);
    border: none;
    color: white;
}