.update {
    margin: 5px;
    padding: 5px;
    border-radius: 3px;
}

.update li,
.update h4 {
    text-align: left;
}

.itemList {
    list-style-type: disc;
}