.cloud-saves-menu-box {
    height: auto;
    width: auto;
    transition: all 0.3s;
}

.CloudSavesMenu { 
    height: auto;
    width: auto;
    margin: 10px auto;
}

.CloudSavesMenu h1{ 
    font-size: 30px;
    text-align: left;
}

.cloud-collections-list-box-outer {
    background-color: #2ecc71;
    border-radius: 5px;
    margin: 2% auto;
    padding: 20px;
    width: 90%;
    height: auto;
    display: flex;
    flex-direction: column;
}

.cloud-saves-title {
    float: left;
    font-size: 20px;
    color: white;
}

.cloud-saves-title::after{
    display: block;
    width: 60%;
    margin: auto;
    height: 1px;
    margin-top: 5px;
    background-color:#f5d44f;
    content:'';
}

.CloudCollectionsListBox {
    display:grid;
    height: auto;
    width: 100%;
    grid-template-columns: repeat(auto-fill, minmax(20%, 1fr));
    row-gap: 15px;
    column-gap: 15px;
}

.add-collection-btn {
    background-color: #f5d44f;
    width: 100%;
    min-width: 100px;
    min-height: 100px;
    height: 100%;
    border-radius: 5px;
    box-shadow: 2px 5px 4px #1e874b;
    justify-self: center;
    align-self: center;
}

.add-collection-btn ion-icon {
    vertical-align: middle;
    font-size: 40px;
}
