.outer {
    background-color: white;
    border-radius: 5px;
    width: 90vw;
    height: auto;
    margin: auto;
    margin-top: 15px;
    position: relative;
}

.outer h2 {
    padding: 10px 0;
}

.outer button {
    position: absolute;
    top: 5px;
    left: 5px;
    width: 30px;
    height: 30px;
    border-radius: 1000px;
}

.outer p {
    text-align: left;
    margin: 15px;
    padding-bottom: 10px;
}