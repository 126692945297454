.loading-spinner {
    z-index: 1;
    width: 100%;
    min-width: 75px;
    height: 100%;
    min-height: 75px;
    display: flex;
    align-self: center;
    justify-self: center;
    justify-content: center;
    align-items: center;
    background: none;
    margin: auto;
}

.opaqueScreen-loader {
    z-index: 2;
    justify-content: center;
    align-items: center;
    background: rgba(0,0,0,0.5);
    text-align: center;
    display: flex;
    flex-direction: column;
    border-radius: 20px;
    box-shadow: 0 0 10px black;
    height: 50px;
    width: 50px;
}

.grid-loader {
    display: grid;
    height: 75px;
    width: 75px;
    grid-template-columns: repeat(auto-fit, minmax(25%, 1fr));
    grid-template-rows: repeat(auto-fit, minmax(25%, 1fr));
    column-gap: 1%;
    row-gap: 1%;
}

.grid-loader-square {
    width: 100%;
    height: 100%;
    border-radius: 2px;
    margin: auto;
    background: #2ecc71;
}

@keyframes wavePulse {
    0% {opacity: 0.01}
    50% {opacity: 1}
    100% {opacity: 0.01}
}

.first {
    animation: wavePulse 1.5s infinite linear;
}

.second {
    animation: wavePulse 1.5s infinite linear;
    animation-delay: .3s;
}

.third {
    animation: wavePulse 1.5s infinite linear;
    animation-delay: .6s;
}

.fourth {
    animation: wavePulse 1.5s infinite linear;
    animation-delay: .9s;
}

.fifth {
    animation: wavePulse 1.5s infinite linear;
    animation-delay: 1.2s;
}

