.cancel-button {
    border: none;
    background-color: rgba(128, 128, 128, 0.103);
    padding: 1vh;
    color: black;
    border-radius: 5px;
}

.cancel-button:hover {
    background-color: rgba(128, 128, 128, 0.212);
    border: none;
    color: black;
}

.modal-button {
    border: 1px solid white;
    padding: 1vh;
    border-radius: 5px;
}

.modal-button:hover {
    border: 1px solid #2ecc71;
}

.disable {
    background-color: grey;
}