.navRight {
    display: flex;
    justify-content: flex-end;
    flex-direction: row;
    flex-grow: 1;
}

.navLeft {
    display: flex;
    justify-content: left;
    margin-top: 5px;
}

.navLeft a {
    text-decoration: none;
    color: black;
}

.siteInfo {
    display: flex;
    flex-direction: column;
}

.siteInfoInner {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: space-evenly;
    margin: 3px;
    width: 100%;
    font-size: .7rem;
    cursor: pointer;
}

.siteInfoInner nav {
    margin: 2px;
    line-height: .8rem;
}

.version {
    margin: auto auto auto 20px;
    font-size: 12px;
}