.errorMessageBtn {
    width: 2vw;
    height:2vw;
    margin: auto;
    padding: 0;
    color: #2ecc71;
    background-color: white;
    border-radius: 1000px;
}

.errorMessageBtn:hover {
    background-color: rgb(230, 230, 230);
    border: none;
}

.errorMessageBtn ion-icon {
    font-size: 20px;
    padding: 0;
    vertical-align: top;
}

.results-title {
    font-size: 5vw;
    margin: auto;
}

.results-winner {
    font-size: 3vw;
    background: #f5d44f;
    border-radius: .25vw;
    margin-bottom: 1vh;
    color: black;
}

.results-other {
    margin: auto auto .5vh auto;
    width: 50%;
    border: 1px solid black;
    border-radius: .25vw;
}

.results-other div {
    font-size: 2vw;
}