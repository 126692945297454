.board {
  height: 100vh;
  width: 100vw;
  margin: 0px;
  transition: all 0.3s;
}

.board-inner {
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
}

.card-width-range {
  width: 200px;
}

.cards-con-out {
  margin: .4rem;
  box-shadow: 0px 0px 10px 1px #d2ab0c;
  border: 0.5px solid grey;
  border-radius: 5px;
  background-color: white;
  flex-grow: 1;
  overflow: auto;
  padding: 5px;
}

  .cards-container {
    display: grid;
    grid-template-columns: repeat( auto-fill, minmax(150px, 1fr));
    justify-content: center;
    align-items: center;
    gap: 5px;
    margin: 15px;
  }

  .card-container {
    aspect-ratio: 16/10;
  }

  .gamecard {
    display: flex;
    align-items: center;
    justify-content: center;
    min-height: 100%;
    min-width: 100%;
    height: 100%;
    width: 100%;
    transition: min-width 0.3s, min-height 0.3s, transform 0.3s, box-shadow 0.3s, opacity 0.3s;
    transition-timing-function: cubic-bezier(1, 0, 0.38, 1.92);
    font-weight: bold;
    background: #2ecc71;
    border: 1px solid #27ae60;
    box-shadow: 2px 2px 2px #a1a1a1;
    border-radius: 10px;
    transition: all 0.2s;
  }

  .gamecard:hover {
    transform: scale(1.03);
    background-color: #39f286;
  }

  .gamecard img,
  .gamecard p,
  .gamecard audio,
  .gamecard iframe {
    margin: 0;
    padding: 0;
    height: 100%;
    width: auto;
    object-fit: cover;
}

.gamecard p {
    margin-bottom: 0;
    align-self: center;
    width: 100%;
    height: auto;
    word-wrap: break-word;
    line-height: 1.5rem;
}

.gamecard audio {
    max-width: 100%;
    height: 50%;
    width: 100%;
}

.gamecard iframe {
    width: auto;
    max-width: 100%;
    height: 100%;
}
  
  @keyframes flip {
    0% {
      transform: scale(1);
    }
    50% {
      transform: scale(1.2);
    }
    100% {
      transform: scale(1);
    }
  }

  .flip {
    animation-name: flip;
    animation-duration: .4s;
    animation-timing-function: cubic-bezier(1, 0, 0.38, 1.92);
    animation-iteration-count: 1;
  }

  .flipped,
  .flipped:hover {
    background-color: #ecf0f1;
    border: 1px solid #cfd9db;
    width: 100%;
    height: 100%;
  }

  .scale:hover {
    transform: scale(1.2);
  }

  
  .card-content {
    font-size: 1.2rem;
    line-height: 1.2rem;
    height: 100%;
    width: 100%;
    overflow:hidden;
    border-radius: 10px;
    margin: auto;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    -webkit-user-select: none; /* Safari */        
    -moz-user-select: none; /* Firefox */
    -ms-user-select: none; /* IE10+/Edge */
    user-select: none; /* Standard */
}

.matched {
  opacity: .5;
  box-shadow: none;
  transition: opacity .5s, box-shadow .5s, transform .5s;
  transition-timing-function: cubic-bezier(1, 0, 0.38, 1.92);
}

.board-game-id {
  position: absolute;
  bottom: 1%;
  left: 1%;
  width: auto;
  height: auto;
  font-size: 13px;
  background-color:white;
  padding: 2px;
  border-radius: 3px;
}

.board-topbar {
    font-size: 2.5vw;
}

.board-button-box {
  position: absolute;
  top: 2%;
  left: 1%;
  width: auto;
  height: auto;
  display: flex;
  align-items: center;
  justify-content: center;
}