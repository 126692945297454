.logo {
    display: flex;
    margin: 10px;
    align-content: center;
    justify-items: left;
    border-radius: 5px;
}

.logo-f,
.logo-l,
.logo-i,
.logo-p,
.logo-G,
.logo-E,
.logo-T {
    display: inline-block;
    width: 100%;
    height:100%;
    margin: none;
    padding: none;
}

.logo-f:hover .logo-inner-1-1,
.logo-l:hover .logo-inner-1-2,
.logo-i:hover .logo-inner-1-3,
.logo-p:hover .logo-inner-1-4,
.logo-G:hover .logo-inner-2-1,
.logo-E:hover .logo-inner-2-2,
.logo-T:hover .logo-inner-2-3 {
    transform:rotateY(-180deg);
}

.logo-inner-1-1,
.logo-inner-1-2,
.logo-inner-1-3,
.logo-inner-1-4,
.logo-inner-2-1,
.logo-inner-2-2,
.logo-inner-2-3 {
    position: relative;
    display: inline-block;
    width: 2rem;
    height: 2rem;
    text-align: center;
    transition: transform 0.5s;
    transform-style: preserve-3d;
}

.logo-front,
.logo-back {
    position: absolute;
    display: inline-block;
    top: 0;
    left: 0;
    -webkit-backface-visibility: hidden; /* Safari */
    backface-visibility: hidden;
    font-size: 1.5rem;
    box-shadow: none;
    height: 100%;
    width: 100%;
    border-radius: 5px;
    border: 1px solid #cfd9db;
    box-shadow: 2px 5px 4px #a82315;
    background-color: white;
    font-family: 'Balsamiq Sans', 'Lato', 'Kosugi', cursive, sans-serif;
}

.text-flex {
    width: 100%;
    height: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
}

.text-flex p {
    margin-bottom: 0;
    vertical-align: text-top;
}

.logo-back {
    transform: rotateY(180deg);
}