* {
  text-align: center;
  scroll-behavior: smooth;
}

input {
  border: 1px solid black;
  font-family: 'Lato', sans-serif;
  margin-top: 10px;
  width: 75%;
}

button {
  background-color: #2ecc71;
  border-radius: 30px;
  width: auto;
  height: auto;
  border: none;
  transition: background-color 0.2s, padding 0.2s, color 0.2s;
  color: white;
  font-family: 'Lato', sans-serif;
}

label {
  font-size:1rem;
  margin: 5px;
  font-family: 'Lato', sans-serif;
}

body {
  background-color: #f5d44f;
}

h1 {
  font-size: 4em;
  border: 1px solid #cfd9db;
  box-shadow: 2px 5px 4px #d2ab0c;
  border-radius: 50px;
  width:50vw;
  background-color: white;
}

p {
  text-align: center;
}

.app {
  height: auto;
  width: auto;
  min-width: 98vw;
  min-height: 98vh;
}

.language-pane {
  position: fixed;
  bottom: 1%;
  right: 0;
}

.language-btn {
  background: none;
  width: auto;
  height: auto;
  margin: 3px;
  padding: 3px;
  border-radius: 1vw;
  font-size: 15px;
}

.language-btn:hover {
  background: none;
  border: none;
}

.language-btn-selected {
  background: none;
  color: black;
  width: auto;
  height: auto;
  margin: 3px;
  padding: 3px;
  border-radius: 1vw;
  font-size: 15px;
}

.language-btn-selected:hover {
  border: none;
}