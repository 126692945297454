.user-info {
  height: 100%;
  width: auto;
  display: flex;
  text-align: center;
  overflow:auto;
}

.user-info button {
  padding: 5px;
  margin: auto 5px;
  border-radius: 5px;
  background-color: #f5d44f;
}

.user-info .alt {
  background: none;
  font-size: 1rem;
}

.user-info p {
  margin: auto;
}
