.create-game-title {
    font-size: 30px;
}

.create-game input {
    width: auto;
    margin: 2px;
}

.create-game input[type="text"] {
    width: 75%;
    padding: 5px;
    margin: 5px 2px;
}

.create-game ul {
    list-style: none;
    display: flex;
    overflow: auto;
}

.create-game ul li {
    display: inline-block;
    padding: 5px;
    font-size: 20px;
}

.create-game-cards p {
    padding: 2px;
    text-overflow: ellipsis;
    overflow: hidden;
    line-height: 1.2rem;
    width: 150px;
}

.create-game-options {
    display: flex;
    align-items: center;
    justify-content: flex-start;
    width: 100%;
    margin: auto;
    overflow: auto;
}

.create-game-options:nth-child(odd) {
    background-color: #f5d44f88;
}

.create-game-options p {
    margin: 5px 20px;
    justify-self: flex-start;
}

.create-game label {
    margin: 2px 10px;
}

.options-inner-div {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
}

.options-inner-div small {
    margin: 2px;
}

.label-group {
    display: flex;
    align-items: center;
    justify-content: center;
}

.grow {
    flex-grow: 1;
}