.collection-preview-flex {
    max-height: 50vh;
    margin: auto;
    overflow-y: auto;
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    align-items: center;
    justify-content: flex-start;
    padding: 5px;
}

.collection-preview-flex .preview-box {
    width: 48%;
    margin: 1%;
    flex-grow: 1;
}