.editor-menu-box {
    height: auto;
    width: 100%;
    align-items: center;
    position:relative;
}

.editor-box {
    position: relative;
    height: auto;
    width: 97%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    background-color: white;
    box-shadow: 2px 5px 4px #d2ab0c;
    border-radius: 5px;
    margin: 4vh auto 3vh auto;
}

.editor-information {
    height: auto;
    width: 97%;
    background-color: white;
    box-shadow: 2px 5px 4px #d2ab0c;
    border-radius: 5px;
    margin: 10px auto;
}

.editor-information h5 {
    padding-top: 10px;
}

.editor-information p {
    text-align: left;
    margin: 5px;
    padding-bottom: 10px;
}

.custom-collection-title {
    margin: 3vh auto;
    text-align: center;
    font-size: 2rem;
    width: 90%;
    height: auto;
    border: none;
    border-bottom: 3px solid #2ecc71;
    outline: none;
}


.custom-collection-box {
    position: relative;
    width: 100%;
    height: auto;
    margin: auto;
}

.custom-collection-box p {
    font-size: 24px;
}

.custom-collection-box input {
    outline: none;
    margin: 5px auto;
    border: 1px solid transparent;
    border-bottom: 1px solid grey;
    width: 95%;
    height: auto;
}

.custom-collection-inner {
    width: 97%;
    height: auto;
    margin: auto;
    border-radius: 5px;
    padding: 3vw;
    display: flex;
    flex-wrap: wrap;
    justify-content: flex-start;
    align-items: stretch;
    background: rgb(224, 224, 224);
}

.collection-edit-buttons-box {
    display: flex;
    margin: 5px auto 10px auto;
}

.custom-collection-card,
.add-card-outline {
    width: 19%;
    margin: .5%;
}

.custom-collection-card {
    border: 1px solid #28b062;
    box-shadow: 2px 5px 4px rgb(182, 182, 182);
    background-color: #2ecc71;
    height: auto;
    border-radius: 5px;
    word-wrap: break-word;
    min-height: 100px;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: stretch;
}

.delete-but-div {
    width: 100%;
    display: flex;
    align-items: flex-start;
    flex-grow: 0;
    flex-shrink: 0;
}

.card-options-div {
    margin-top: auto;
}

.preview-box {
    height: auto;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content:flex-start;
    width: 100%;
}

.preview-box .gamecard {
    flex-grow: 1;
    flex-shrink: 1;
    min-height: 0;
    display: flex;
    align-items: stretch;
    justify-content: center;
    margin: 2px;
    max-width: 95%;
    min-width: 0;
    height: 20vh;
}

.add-card-outline {
    display: flex;
    width: 19%;
    height: auto;
    min-height: 100px;
    border: 1px solid rgba(128, 128, 128, 0.527);
    border-radius: 5px;
    background-color: rgb(255, 255, 255);
    align-items: center;
    justify-items: center;
    margin: .5%;
}

.addCardBtn {
    background-color: #f5d44f;
    margin: auto;
    width: 50px;
    height: 50px;
    padding: 0;
    overflow: hidden;
    border-radius: 200px;
    transition: box-shadow 0.2s, width 0.2s, height 0.2s, font-size 0.2s;
    font-size: 15px;
    line-height: 0.9;
}

.addCardBtn:hover {
    padding: 0;
    width: 60px;
    height: 60px;
    border-radius: 200px;
    font-size: 20px;
    box-shadow: 2px 5px 4px grey;
}

.delete-but {
    width: 20px;
    height: 20px;
    padding: 0;
    font-size: 1vw;
    background: none;
    border-radius: 1000px;
}

.delete-but ion-icon {
    display: block;
    font-size: 20px;
    margin: auto;
}

.submitCustomCollectionBtn {
    margin: 50px auto;
    background-color: #2ecc71;
    width: 60px;
    height: 60px;
    padding: 0;
    border-radius: 5px;
    transition: box-shadow 0.2s, width 0.2s, height 0.2s, font-size 0.2s;
}

.submitCustomCollectionBtn ion-icon {
    font-size: 50px;
    vertical-align: middle;
    color: white;
}

.submitCustomCollectionBtn:hover,
.submitCustomCollectionBtn:active {
    background-color: #2ecc71;
    box-shadow: 2px 5px 4px grey;
    padding: 0;
    width: 70px;
    height: 70px;
}

.editor-box a {
    width: 100%;
    display: flex;
    justify-content: left;
}

.return-but-box {
    display: flex;
    align-items: center;
    justify-content: flex-start;
    width: 100%;
    margin: auto;
}

.return-but-box a {
    width: auto;
}

.return-button {
    border-radius: 5px;
    background-color: #e74c3c;
    border: 1px solid white;
    transition: border 0.2s;
}

.return-button:hover {
    border:none;
    color: #e74c3c;
    border: 1px solid #e74c3c;
}

.return-button ion-icon {
    font-size: 30px;
}