.attack-menu-btn {
    margin: 10px;
    width: auto;
    height: auto;
    padding: 5px;
    border-radius: 5px;
    background: #f5d44f;
    color: black;
    font-size: 20px;
}

.attack-menu-btn:hover {
    border:none;
}

.attack-menu-close-btn {
    width: auto;
    height: auto;
    border-radius: 5px;
    margin: auto;
    background: none;
    color: #2ecc71;
    justify-items: center;
    padding: 0;
}

.attack-menu-close-btn:hover {
    border: none;
    background: rgba(128, 128, 128, 0.144);
}

.attack-menu-close-btn ion-icon {
    font-size: 20px;
    margin: auto;
}